.App {
  text-align: center;
  margin-top: 50px;
  background-color: #fff;
}

#grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: auto;
  max-width: 600px;
}

textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  text-align: center;
}

button {
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .App {
    margin-top: 20px;
  }
}